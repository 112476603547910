<template>
  <div class="view pa24">
    <el-form inline :model="form">
      <el-form-item label="每个企业">
        <el-select
          v-model="form.dayType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            @change="dayType_tap"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可以免费发布">
        <el-input v-model="form.publishNum"></el-input>
      </el-form-item>
      <el-form-item label="条需求,和">
        <el-input v-model="form.proNum"></el-input>
      </el-form-item>
      <el-form-item label="件产品">
        <el-button
          @click="saveSetting"
          style="background-color: #0D6DFF; color: white"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { queryPublishSetting, updatePublishSetting } from "@/api/addedServices";
export default {
  data() {
    return {
      form: {
        dayType: "DAY",
        publishNum: 0,
        proNum: 0,
      },
      options: [
        {
          value: "DAY",
          label: "每日",
        },
        {
          value: "WEEK",
          label: "每周",
        },
        {
          value: "MONTH",
          label: "每月", 
        },
      ],
    };
  },
  created(){
    this.queryPublishSetting()
  },
  methods:{
   async queryPublishSetting(){
     const reslut = await queryPublishSetting({pageSize : 0},{ showLoading: true });
     console.log(reslut,'lwssb');
     this.form = reslut.data ? reslut.data : this.form ;
    },
   async saveSetting(){
      await updatePublishSetting(this.form);
      this.$message({
          message: "保存成功",
          type: "success",
        });
      this.queryPublishSetting()
    },
    dayType_tap(type){
      console.log(type)
    }
  }
};
</script>

<style lang="scss" scoped>
</style>